
import { NgModule } from '@angular/core';
import { CoreRoutingModule } from './core-routing.module';

import { RoleNotAllowedComponent } from './pages/error/role-not-allowed/role-not-allowed.component';
import { GenericErrorComponent } from './pages/error/generic/generic.component';
import { Error404Component } from './pages/error/not-found/404.component';
import { ErrorComponent } from './pages/error/template/error.component';
import { ConfirmDialogComponent } from './services/notifications/components/confirm-dialog/confirm-dialog.component';
import { EmailLandingComponent } from './pages/email-landing/email-landing.component';

import { LoadingOverlayComponent } from './services/loader/components/loading-overlay/loading.component';
import { ParametersService } from './services/parameters/parameters.service';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ModalModule } from '@shared/components/modal/modal.module';
import { MaterialModule } from '../material.module';

@NgModule({
  declarations: [
    ErrorComponent,
    RoleNotAllowedComponent,
    GenericErrorComponent,
    Error404Component,
    ConfirmDialogComponent,
    EmailLandingComponent,
    LoadingOverlayComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    CoreRoutingModule,
    MaterialModule,
    ModalModule,
  ],
  providers: [
    ParametersService,
  ],
  entryComponents: [
    ConfirmDialogComponent,
    LoadingOverlayComponent,
  ]
})
export class CoreModule { }
