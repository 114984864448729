import { StringEnum } from '@core/enums/string-enum-type';
import { EndPoints } from '@core/enums/endpoints';

export class ParametersEndPoints extends StringEnum {
  static getUrl = new ParametersEndPoints(EndPoints.webApiBaseUrl
    .concat(EndPoints.webApiVersion1)
    .add('parameter')
    .toString());

    static basesUrl = new ParametersEndPoints(ParametersEndPoints.getUrl.add('bases').toString());

}
