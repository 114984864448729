import { EndPoints } from '@core/enums/endpoints';
import { StringEnum } from '@core/enums/string-enum-type';

export class XoffEndpoints extends StringEnum {
    public static controllerUrl = new XoffEndpoints(
        EndPoints.webApiBaseUrl.concat(EndPoints.webApiVersion1).add('xoff').toString(),
    );

    public static postUrl = new XoffEndpoints(XoffEndpoints.controllerUrl.toString());

    public static announcement = new XoffEndpoints(XoffEndpoints.controllerUrl.add('announcement').toString());

    public static request = new XoffEndpoints(XoffEndpoints.controllerUrl.add('request').toString());

    public static search = this.announcement.add('search').toString();

    public static requestSearch = this.request.add('search').toString();
}
