import { BaseRequestEndPoints } from './request/base-request.endpoints';
import { MockResponse } from '@vueling-ng/vy-api-mocks';
import * as moment from 'moment';
import { BaseRequestSnapshotEndPoints } from './snapshot/base-request-snapshot.endpoints';
import { BaseRequestUpgradeEndPoints } from './upgrade/base-request-upgrade.endpoints';

export const baseChangesMockResponses: MockResponse[] = [
    {
        url: BaseRequestEndPoints.searchUrl.toString(),
        response: {
            data: [
            {
                id: 1,
                rankId: 'CP',
                crewInfo: {
                  crewId: 1016,
                  rank: 2,
                  alias: 'JUANP',
                  baseId: 'AGP'
                },
                creationDate: moment.utc().add(-4, 'days'),
                updateDate: moment.utc(),
                baseId: 'BCN',
                priority: 1,
                requestStatus: 1,
                notified: false,
                isProvisionalDetachment: true
            },
            {
                id: 2,
                rankId: 'CP',
                crewInfo: {
                  rank: 2,
                  crewId: 1016,
                  alias: 'JUANP',
                  baseId: 'AGP'
                },
                creationDate: moment.utc().add(-4, 'days'),
                updateDate: moment.utc(),
                baseId: 'ORY',
                priority: 2,
                requestStatus: 1,
                notified: false
            },
            {
                id: 3,
                rankId: 'CP',
                crewInfo: {
                  rank: 1,
                  crewId: 1014,
                  alias: 'PEDROA',
                  baseId: 'BCN',
                  notified: false
                },
                creationDate: moment.utc().add(-4, 'days'),
                updateDate: moment.utc(),
                baseId: 'AGP',
                priority: 1,
                requestStatus: 1,
                notified: false
            },
            {
                id: 4,
                rank: 'CP',
                crewInfo: {
                  rank: 1,
                  crewId: 1014,
                  alias: 'PEDROA',
                  baseId: 'BCN',
                  notified: false
                },
                creationDate: moment.utc().add(-4, 'days'),
                updateDate: moment.utc(),
                baseId: 'MAD',
                priority: 2,
                requestStatus: 2,
                notified: true
            },
            {
                id: 5,
                rank: 1,
                crewInfo: {
                  rank: 1,
                  crewId: 1014,
                  alias: 'PEDROA',
                  baseId: 'BCN',
                  notified: false
                },
                requestDateTime: moment.utc(),
                baseId: 'PMI',
                priority: 2,
                requestStatus: 2,
                notified: false
            }],
            paging: {
                page: 1,
                pageCount: 1,
                totalRecordCount: 10
            }
        }
    },
    {
        url: BaseRequestEndPoints.postUrl.toString(),
        response: {
            id: 1,
            rankId: 'CP',
            crewInfo: {
              rank: 2,
              crewId: 1016,
              alias: 'JUANP',
              baseId: 'AGP',
            },
            creationDate: moment.utc().add(-4, 'days'),
            updateDate: moment.utc(),
            baseId: 'BCN',
            priority: 1,
            requestStatus: 1,
        },
    },
    {
        url: BaseRequestEndPoints.postUrl.add('1').add('delete').toString(),
        response: {},
    },
    {
        url: BaseRequestEndPoints.postUrl.add('2').add('delete').toString(),
        response: {},
    },
    {
        url: BaseRequestEndPoints.postUrl.add('3').add('delete').toString(),
        response: {},
    },
    {
        url: BaseRequestEndPoints.postUrl.add('4').add('delete').toString(),
        response: {},
    },
    {
        url: BaseRequestSnapshotEndPoints.datesUrl.toString(),
        response: {
            current: moment.utc().add(-4, 'days'),
            next: moment.utc().add(180, 'days'),
        },
    },
    {
        url: BaseRequestSnapshotEndPoints.regenerateUrl.toString(),
        response: true,
    },
    {
        url: BaseRequestSnapshotEndPoints.searchUrl.toString(),
        response: {
            data: [
            {
                id: 1,
                rankId: 'CP',
                crewInfo: {
                  crewId: 1016,
                  rank: 2,
                  alias: 'JUANP',
                  baseId: 'AGP'
                },
                creationDate: moment.utc().add(-4, 'days'),
                updateDate: moment.utc(),
                baseId: 'BCN',
                priority: 1,
                requestStatus: 1,
                notified: false,
                isProvisionalDetachment: true
            },
            {
                id: 2,
                rankId: 'CP',
                crewInfo: {
                  rank: 2,
                  crewId: 1016,
                  alias: 'JUANP',
                  baseId: 'AGP'
                },
                creationDate: moment.utc().add(-4, 'days'),
                updateDate: moment.utc(),
                baseId: 'ORY',
                priority: 2,
                requestStatus: 1,
                notified: false
            },
            {
                id: 3,
                rankId: 'CP',
                crewInfo: {
                  rank: 1,
                  crewId: 1014,
                  alias: 'PEDROA',
                  baseId: 'BCN',
                  notified: false
                },
                creationDate: moment.utc().add(-4, 'days'),
                updateDate: moment.utc(),
                baseId: 'AGP',
                priority: 1,
                requestStatus: 1,
                notified: false
            },
            {
                id: 4,
                rank: 'CP',
                crewInfo: {
                  rank: 1,
                  crewId: 1014,
                  alias: 'PEDROA',
                  baseId: 'BCN',
                  notified: false
                },
                creationDate: moment.utc().add(-4, 'days'),
                updateDate: moment.utc(),
                baseId: 'MAD',
                priority: 2,
                requestStatus: 2,
                notified: true
            },
            {
                id: 5,
                rank: 1,
                crewInfo: {
                  rank: 1,
                  crewId: 1014,
                  alias: 'PEDROA',
                  baseId: 'BCN',
                  notified: false
                },
                requestDateTime: moment.utc(),
                baseId: 'PMI',
                priority: 2,
                requestStatus: 2,
                notified: false
            }],
            paging: {
                page: 1,
                pageCount: 1,
                totalRecordCount: 10
            }
        }
    },
    {
        url: BaseRequestSnapshotEndPoints.postUrl.toString(),
        response: {
            id: 1,
            rankId: 'CP',
            crewInfo: {
              rank: 2,
              crewId: 1016,
              alias: 'JUANP',
              baseId: 'AGP',
            },
            creationDate: moment.utc().add(-4, 'days'),
            updateDate: moment.utc(),
            baseId: 'BCN',
            priority: 1,
            requestStatus: 1,
        },
    },
    {
        url: BaseRequestSnapshotEndPoints.notifyUrl.toString(),
        response: {}
    },
    {
        url: BaseRequestUpgradeEndPoints.searchUrl.toString(),
        response: {
            data: [
            {
                id: 1,
                rankId: 'CP',
                crewInfo: {
                  crewId: 1016,
                  rank: 2,
                  alias: 'JUANP',
                  baseId: 'AGP'
                },
                creationDate: moment.utc().add(-4, 'days'),
                updateDate: moment.utc(),
                baseId: 'BCN',
                priority: 1,
                requestStatus: 1,
                notified: false,
                isProvisionalDetachment: true
            },
            {
                id: 2,
                rankId: 'CP',
                crewInfo: {
                  rank: 2,
                  crewId: 1016,
                  alias: 'JUANP',
                  baseId: 'AGP'
                },
                creationDate: moment.utc().add(-4, 'days'),
                updateDate: moment.utc(),
                baseId: 'ORY',
                priority: 2,
                requestStatus: 1,
                notified: false
            },
            {
                id: 3,
                rankId: 'CP',
                crewInfo: {
                  rank: 1,
                  crewId: 1014,
                  alias: 'PEDROA',
                  baseId: 'BCN',
                  notified: false
                },
                creationDate: moment.utc().add(-4, 'days'),
                updateDate: moment.utc(),
                baseId: 'AGP',
                priority: 1,
                requestStatus: 1,
                notified: false
            },
            {
                id: 4,
                rank: 'CP',
                crewInfo: {
                  rank: 1,
                  crewId: 1014,
                  alias: 'PEDROA',
                  baseId: 'BCN',
                  notified: false
                },
                creationDate: moment.utc().add(-4, 'days'),
                updateDate: moment.utc(),
                baseId: 'MAD',
                priority: 2,
                requestStatus: 2,
                notified: true
            },
            {
                id: 5,
                rank: 1,
                crewInfo: {
                  rank: 1,
                  crewId: 1014,
                  alias: 'PEDROA',
                  baseId: 'BCN',
                  notified: false
                },
                requestDateTime: moment.utc(),
                baseId: 'PMI',
                priority: 2,
                requestStatus: 2,
                notified: false
            }],
            paging: {
                page: 1,
                pageCount: 1,
                totalRecordCount: 10
            }
        }
    },
    {
        url: BaseRequestUpgradeEndPoints.postUrl.toString(),
        response: {
            id: 1,
            rankId: 'CP',
            crewInfo: {
              rank: 2,
              crewId: 1016,
              alias: 'JUANP',
              baseId: 'AGP',
            },
            creationDate: moment.utc().add(-4, 'days'),
            updateDate: moment.utc(),
            baseId: 'BCN',
            priority: 1,
            requestStatus: 1,
        },
    },
    {
        url: BaseRequestUpgradeEndPoints.notifyUrl.toString(),
        response: {}
    },
];
