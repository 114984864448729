import { Component } from '@angular/core';

/**
 * Error page that captures errors for routes not matching
 */
@Component({
    templateUrl: '404.component.html'
})
export class Error404Component {
}
