import { StringEnum } from '@core/enums/string-enum-type';
import { EndPoints } from '@core/enums/endpoints';

export class DetachmentSlotEndPoints extends StringEnum {
  static controllerUrl = new DetachmentSlotEndPoints(EndPoints.webApiBaseUrl
    .concat(EndPoints.webApiVersion1)
    .add('detachment-slot')
    .toString());

    static forAnnouncementUrl = new DetachmentSlotEndPoints(DetachmentSlotEndPoints.controllerUrl
        .add('for-announcement')
        .toString());

    static postUrl = new DetachmentSlotEndPoints(DetachmentSlotEndPoints.controllerUrl
        .toString());
}
