import { StringEnum } from './string-enum-type';

export class Role extends StringEnum {
  static Admin = new Role('Admin');
  static SuperAdmin = new Role('SuperAdmin');
  static Bases = new Role('Bases');
  static Detachments = new Role('Detachments');
  static Scales = new Role('Scales');
  static Emulation = new Role('Emulation');
  static FeatureToggle = new Role('FeatureToggle');
  static Documentation = new Role('Documentation');
  static PartTime = new Role('PartTime');
  public static Safety = new Role('Safety');
}
