import { StringEnum } from '@core/enums/string-enum-type';
import { EndPoints } from '@core/enums/endpoints';

export class DaysEndPoints extends StringEnum {
  static controllerUrl = new DaysEndPoints(EndPoints.webApiBaseUrl
    .concat(EndPoints.webApiVersion1)
    .add('part-time')
    .toString());

    static partTimeDays = new DaysEndPoints(DaysEndPoints.controllerUrl
        .add('day')
        .toString());
}
