import { FileServiceEndPoints } from '@shared/services/file/file-service-endpoints';
import { MockResponse } from '@vueling-ng/vy-api-mocks';
import { HttpHeaders } from '@angular/common/http';
import { BaseEndPoints } from './bases/base.endpoints';

export const sharedMockResponses: MockResponse[] = [
    {
        url: BaseEndPoints.controllerUrl.toString(),
        response: [
            {
                id: 1,
                name: 'BCN',
              },
              {
                id: 2,
                name: 'ORY',
              },
              {
                id: 3,
                name: 'ALC',
              },
              {
                id: 4,
                name: 'VLC',
              },
              {
                id: 5,
                name: 'MAD',
              }
        ],
    },
    {
      url: BaseEndPoints.countryUrl.add('LE').toString(),
      response: [
            {
              id: 1,
              name: 'BCN',
              detachmentAvailabilities: {
                fo: true,
                cp: true,
              },
            },
            {
              id: 3,
              name: 'ALC',
              detachmentAvailabilities: {
                fo: true,
                cp: true,
              },
            },
            {
              id: 4,
              name: 'VLC',
              detachmentAvailabilities: {
                fo: true,
                cp: true,
              },
            },
            {
              id: 5,
              name: 'MAD',
              detachmentAvailabilities: {
                fo: true,
                cp: true,
              },
            }
      ],
    },
    {
      url: BaseEndPoints.countryUrl.add('LF').toString(),
      response: [
            {
              id: 2,
              name: 'ORY',
              detachmentAvailabilities: {
                fo: true,
                cp: true,
              },
            },
      ],
    },
    {
      url: BaseEndPoints.countryUrl.add('LI').toString(),
      response: [
            {
              id: 1,
              name: 'FCO',
              detachmentAvailabilities: {
                fo: true,
                cp: true,
              },
            },
      ],
    },
    {
      url: BaseEndPoints.searchUrl.toString(),
      response: {
        data: [
          {
            id: 1,
            name: 'BCN',
            baseAvailabilities: {
              fo: true,
              cp: true,
            },
            detachmentAvailabilities: {
              fo: true,
              cp: true,
            },
          },
          {
            id: 2,
            name: 'ORY',
          },
          {
            id: 3,
            name: 'ALC',
            baseAvailabilities: {
              fo: true,
              upg: true,
            },
            detachmentAvailabilities: {
              fo: true,
              cp: true,
            },
          },
          {
            id: 4,
            name: 'VLC',
          },
          {
            id: 5,
            name: 'MAD',
            baseAvailabilities: {
              fo: true,
              cp: true,
              upg: true
            },
            detachmentAvailabilities: {
              fo: true,
              op: true,
            },
          }
        ],
        paging: {
          page: 1,
          pageCount: 1,
          totalRecordCount: 350
        }
      },
    },
    {
      url: BaseEndPoints.saveBaseUrl.toString(),
      response: {},
    },
    {
      url: BaseEndPoints.saveDetachmentUrl.toString(),
      response: {},
    },
    {
        url: FileServiceEndPoints.fileController.add('86c7e86c-3971-441d-b40f-bbfdab55c3d2').toString(),
        headers: new HttpHeaders({
            'content-disposition': 'attachment; filename="file1.jpeg"'
        }),
        response: "data:image/jpeg;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAIAAAACDbGyAAAAYElEQVR4nABQAK//BB5T8PBXdkMntBnDjRs7wQOk+gch3vA7XzJ7iq9rEfIEay0rDvi0GOIIA2P5cSWkBB3rvssKZZ0MWt7PHTaw3AAy7/jYXnNd8iieBLAJ4KkBAAD//3fLJBIaHl5wAAAAAElFTkSuQmCC"
    },
    {
        url: FileServiceEndPoints.fileController.add('1a973694-bb54-4a0f-b48b-1fd1ad9a5941').toString(),
        headers: new HttpHeaders({
            'content-disposition': 'attachment; filename="file2.jpg"'
        }),
        response: "data:image/jpeg;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAIAAAACDbGyAAAAYElEQVR4nABQAK//BB5T8PBXdkMntBnDjRs7wQOk+gch3vA7XzJ7iq9rEfIEay0rDvi0GOIIA2P5cSWkBB3rvssKZZ0MWt7PHTaw3AAy7/jYXnNd8iieBLAJ4KkBAAD//3fLJBIaHl5wAAAAAElFTkSuQmCC"
    },
    {
        url: FileServiceEndPoints.fileController.add('5fe1f47a-2904-423f-844e-148b1ed770e4').toString(),
        headers: new HttpHeaders({
            'content-disposition': 'attachment; filename="convenio_de_pilotos.jpg"'
        }),
        response: "data:image/jpeg;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAIAAAACDbGyAAAAYElEQVR4nABQAK//BB5T8PBXdkMntBnDjRs7wQOk+gch3vA7XzJ7iq9rEfIEay0rDvi0GOIIA2P5cSWkBB3rvssKZZ0MWt7PHTaw3AAy7/jYXnNd8iieBLAJ4KkBAAD//3fLJBIaHl5wAAAAAElFTkSuQmCC"
    },
    {
        url: FileServiceEndPoints.fileController.toString(),
        response: '1a973694-bb54-4a0f-b48b-1fd1ad9a5941'
    },
];
