import { StringEnum } from '@core/enums/string-enum-type';
import { EndPoints } from '@core/enums/endpoints';

export class PartTimeRequestsEndPoints extends StringEnum {
    static controllerUrl = new PartTimeRequestsEndPoints(EndPoints.webApiBaseUrl
        .concat(EndPoints.webApiVersion1)
        .add('part-time')
        .toString());

    static partTimeRequests = new PartTimeRequestsEndPoints(PartTimeRequestsEndPoints.controllerUrl
        .add('search')
        .toString());
}