import { Component, Input } from '@angular/core';

@Component({
    templateUrl: 'error.component.html',
    selector: 'app-error-page',
})
export class ErrorComponent {
    @Input() contentImageSrc: string;
    @Input() title: string;
    @Input() subtitle: string;
    @Input() titleDefault: string;
    @Input() subtitleDefault: string;
    @Input() showActions: boolean = true;
}
