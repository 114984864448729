import { StringEnum } from '@core/enums/string-enum-type';
import { EndPoints } from '@core/enums/endpoints';

export class CrewDocumentFilesEndPoints extends StringEnum {
    static controllerUrl = new CrewDocumentFilesEndPoints(EndPoints.webApiBaseUrl
        .concat(EndPoints.webApiVersion1)
        .add('documentation')
        .add('crew-document-file')
        .toString()
    );

    static forDocumentUrl = new CrewDocumentFilesEndPoints(CrewDocumentFilesEndPoints.controllerUrl
        .add('for-document')
        .toString()
      );
}
