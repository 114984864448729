import { StringEnum } from '@core/enums/string-enum-type';
import { EndPoints } from '@core/enums/endpoints';

export class BaseRequestEndPoints extends StringEnum {
  private static _controllerUrl = new BaseRequestEndPoints(EndPoints.webApiBaseUrl
    .concat(EndPoints.webApiVersion1)
    .add('base-request')
    .toString());

  static searchUrl = new BaseRequestEndPoints(BaseRequestEndPoints._controllerUrl
    .add('search')
    .toString());

  static postUrl = new BaseRequestEndPoints(BaseRequestEndPoints._controllerUrl
    .toString());
}
