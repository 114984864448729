import { EndPoints } from '@core/enums/endpoints';
import { StringEnum } from '@core/enums/string-enum-type';

export class XoffRequestEndpoints extends StringEnum {
    public static controllerUrl = new XoffRequestEndpoints(
        EndPoints.webApiBaseUrl.concat(EndPoints.webApiVersion1).add('xoff').toString(),
    );

    public static postUrl = new XoffRequestEndpoints(XoffRequestEndpoints.controllerUrl.toString());

    public static request = new XoffRequestEndpoints(XoffRequestEndpoints.controllerUrl.add('request').toString());

    public static requestSearch = this.request.add('search').toString();
}
