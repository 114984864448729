import { defaultEnvironment, protectedResourceMap } from './environment.default';
protectedResourceMap.set('#{{WEB_API_URL}}#', ['#{{BFF_CLIENT_ID}}#/user_access', 'User.Read']);
protectedResourceMap.set('#{{WEB_API_MYSTATS_URL}}#/v1', ['#{{BFF_CLIENT_ID_MYSTATS}}#/user_access']);

export const environment = {
    ...defaultEnvironment,
    production: true,
    webApiDomain: '#{{WEB_API_URL}}#',
    webApiMyStats: '#{{WEB_API_MYSTATS_URL}}#',
    old_crew_url: '#{{OLD_CREW_URL}}#',
    atc_auth_config: {
        ...defaultEnvironment.atc_auth_config,
        auth: {
            clientId: '#{{APP_CLIENT_ID}}#',
            redirectUri: '#{{APP_REDIRECT_URL}}#'
        },
        framework: {
            ...defaultEnvironment.atc_auth_config.framework,
            protectedResourceMap: protectedResourceMap
        }
    },
    atc_log_config: {
        console_enabled: true,
        console_level: 'error'
    },
    gtmTrackingId: '#{{GOOGLE_TAG_MANAGER_TRACKING_ID}}#',
    mapBoxToken: '#{{MAPBOX_TOKEN}}#',

};
