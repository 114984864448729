import { Injectable } from '@angular/core';
import { Observable, of, timer  } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { PreloadingStrategy, Route } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class PreloadingStrategyService implements PreloadingStrategy {
    preload(route: Route, loadMe: () => Observable<any>): Observable<any> {

    if (route?.data['preload']) {
      const delay: number = route.data['delay'] ? route.data['delay'] : 0;
      return timer(delay).pipe(
        mergeMap(() => {
          return loadMe() ;
        }));
    } else {
      return of(null);
    }
  }
}
