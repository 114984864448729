import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IModuleTranslationOptions, ModuleTranslateLoader } from '@larscom/ngx-translate-module-loader';
import {
    MissingTranslationHandler,
    MissingTranslationHandlerParams,
    TranslateLoader,
    TranslateModuleConfig,
    TranslateService,
} from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Injectable()
export class MissingTranslationHelper implements MissingTranslationHandler {
    public handle(params: MissingTranslationHandlerParams): string {
        if (params.interpolateParams) {
            return (params.interpolateParams['Default'] || params.key) as string;
        }

        return params.key;
    }
}

export function moduleHttpLoaderFactory(http: HttpClient): ModuleTranslateLoader {
    const baseTranslateUrl = './assets/i18n';

    const options: IModuleTranslationOptions = {
        modules: [
            { baseTranslateUrl },
            { baseTranslateUrl, moduleName: 'bases' },
            { baseTranslateUrl, moduleName: 'detachments' },
            { baseTranslateUrl, moduleName: 'crew-emulation' },
            { baseTranslateUrl, moduleName: 'feature-toggle' },
            { baseTranslateUrl, moduleName: 'documentation' },
            { baseTranslateUrl, moduleName: 'part-time' },
            { baseTranslateUrl, moduleName: 'scales' },
            { baseTranslateUrl, moduleName: 'public' },
            { baseTranslateUrl, moduleName: 'my-stats' },
            { baseTranslateUrl, moduleName: 'xoff' },
        ],
        lowercaseNamespace: true,
    };

    return new ModuleTranslateLoader(http, options);
}

export const translateConfig: TranslateModuleConfig = {
    loader: {
        provide: TranslateLoader,
        useFactory: moduleHttpLoaderFactory,
        deps: [HttpClient],
    },
    missingTranslationHandler: { provide: MissingTranslationHandler, useClass: MissingTranslationHelper },
    useDefaultLang: true,
};

export function appInitializerFactory(translate: TranslateService) {
    return (): Observable<unknown> => {
        translate.setDefaultLang('es');
        return translate.use('es');
    };
}
