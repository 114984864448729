import { StringEnum } from '@core/enums/string-enum-type';
import { EndPoints } from '@core/enums/endpoints';

export class DetachmentEndPoints extends StringEnum {
  static controllerUrl = new DetachmentEndPoints(EndPoints.webApiBaseUrl
    .concat(EndPoints.webApiVersion1)
    .add('detachment-announcement')
    .toString());

    static searchUrl = new DetachmentEndPoints(DetachmentEndPoints.controllerUrl
        .add('search')
        .toString());

    static postUrl = new DetachmentEndPoints(DetachmentEndPoints.controllerUrl
        .toString());
}
