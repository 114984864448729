import { Injectable } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { MediaObserver, MediaChange } from '@angular/flex-layout';

@Injectable()
export class ModalLayoutService {
    public isMobile = false;

    constructor(
        private mediaObserver: MediaObserver,
    ) {

        // MOBILE LAYOUT
        mediaObserver.asObservable().subscribe((changes: MediaChange[]) => {
            this.isMobile = changes.filter((c) => c.mqAlias === 'lt-md').length > 0;
        });
    }

    public getModalStyleConfigs(): MatDialogConfig {
        if (this.isMobile) {
           return {
                height: '100%',
                width: '100%',
                minHeight: '100%',
                minWidth: '100%',
                panelClass: 'modal-panel-mobile',
                autoFocus: false,
                disableClose: true,
            };
        }
        else {
            return {
                panelClass: 'modal-panel',
                autoFocus: false,
                width: '930px',
                disableClose: true,
            };
        }
    }
}
