import { NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HttpClientModule } from '@angular/common/http';
import { TranslateModule, TranslateService} from '@ngx-translate/core';
import { appInitializerFactory, translateConfig } from '@core/utils/traslate.configs';

import { AppInterceptors } from '@core/interceptors/interceptors.provider';
import { AppGuards } from '@core/guards/guards.provider';
import { BrowserModule } from '@angular/platform-browser';
import { AtcAuthModule } from '@vueling-ng/atc-auth';
import { CoreModule } from '@core/core.module';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { VyThemeModule } from './vy-theme/vy-theme.module';
import { AtcLogModule, AtcLogErrorHandler } from '@vueling-ng/atc-log';

import { VyMocksModule } from '@vueling-ng/vy-api-mocks';
import { VyGoogleModule } from '@vueling-ng/vy-google-services';
import { APP_MOCK_CONFIG } from 'src/mocks';

import localeSpanish from '@angular/common/locales/es';
import localEnglish from '@angular/common/locales/en';
import { registerLocaleData } from '@angular/common';

registerLocaleData(localeSpanish);
registerLocaleData(localEnglish);

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AtcAuthModule.forRoot(environment.atc_auth_config),
    AtcLogModule.forRoot(environment.atc_log_config),
    VyMocksModule.forRoot({ enabled: environment.use_mock, mockResponses: APP_MOCK_CONFIG }),
    VyGoogleModule.forRoot({ tagManagerTrackingId: environment.gtmTrackingId }),
    CoreModule,
    VyThemeModule,
    AppRoutingModule,
    TranslateModule.forRoot(translateConfig)
  ],
  providers: [
    {
        provide: ErrorHandler,
        useClass: AtcLogErrorHandler
    },
    AppInterceptors,
    AppGuards,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

