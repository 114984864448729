import { StringEnum } from '@core/enums/string-enum-type';
import { EndPoints } from '@core/enums/endpoints';

export class FullScaleEndPoints extends StringEnum {
  private static _controllerUrl = new FullScaleEndPoints(EndPoints.webApiBaseUrl
    .concat(EndPoints.webApiVersion1)
    .add('full-scale')
    .toString());

  static searchUrl = new FullScaleEndPoints(FullScaleEndPoints._controllerUrl
    .add('search')
    .toString());

  static postUrl = new FullScaleEndPoints(FullScaleEndPoints._controllerUrl
    .toString());
}
