import { StringEnum } from '@core/enums/string-enum-type';
import { EndPoints } from '@core/enums/endpoints';

export class DocumentsEndPoints extends StringEnum {
  static controllerUrl = new DocumentsEndPoints(EndPoints.webApiBaseUrl
    .concat(EndPoints.webApiVersion1)
    .add('documentation')
    .add('document-type')
    .toString());

    static searchUrl = new DocumentsEndPoints(DocumentsEndPoints.controllerUrl
        .add('search')
        .toString());

    static postUrl = new DocumentsEndPoints(DocumentsEndPoints.controllerUrl
        .toString());
}
