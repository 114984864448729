import { environment } from 'src/environments/environment';
import { StringEnum } from './string-enum-type';

export class EndPoints extends StringEnum {
    static webApiBaseUrl = new EndPoints(environment.webApiDomain).add('api');
    public static webApiMyStats = new EndPoints(environment.webApiMyStats);

    // ApiVersions
    static webApiVersion1 = new EndPoints('v1');
    static webApiVersion2 = new EndPoints('v2');

  }
