import { StringEnum } from '@core/enums/string-enum-type';
import { EndPoints } from '@core/enums/endpoints';

export class DetachmentsOperativeEndPoints extends StringEnum {
  private static _controllerUrl = new DetachmentsOperativeEndPoints(EndPoints.webApiBaseUrl
    .concat(EndPoints.webApiVersion1)
    .add('detachment-operative')
    .toString());

  static searchUrl = new DetachmentsOperativeEndPoints(DetachmentsOperativeEndPoints._controllerUrl
    .add('search')
    .toString());
}
