import { MockResponse } from '@vueling-ng/vy-api-mocks';
import * as moment from 'moment';
import { CrewEmulationEndPoints } from './crew-emulation/crew-emulation.endpoints';

export const emulationMockResponses: MockResponse[] = [
    {
        url: CrewEmulationEndPoints.searchUrl.toString(),
        response: {
            data: [
            {
                id: 1,
                updateDate: moment.utc(),
                email: 'ivo.pelayo@col.vueling.com',
                phone: '',
                crewId: 1014
            },
            {
                id: 1,
                updateDate: moment.utc(),
                email: 'diego.castano@col.vueling.com',
                phone: '',
                crewId: 6568
            }
            ],
            paging: {
                page: 1,
                pageCount: 1,
                totalRecordCount: 2
            }
        }
    },
    {
        url: CrewEmulationEndPoints.postUrl.toString(),
        response: {
            id: 1,
            updateDate: moment.utc(),
            email: 'ivo.pelayo@col.vueling.com',
            phone: '',
            crewId: 1014
        },
    },
    {
        url: CrewEmulationEndPoints.postUrl.add('1').add('delete').toString(),
        response: true,
    },

];
