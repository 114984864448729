import { StringEnum } from '@core/enums/string-enum-type';
import { EndPoints } from '@core/enums/endpoints';

export class DocumentsAimsEndPoints extends StringEnum {
  static controllerUrl = new DocumentsAimsEndPoints(EndPoints.webApiBaseUrl
    .concat(EndPoints.webApiVersion1)
    .add('documentation')
    .add('aims')
    .toString());

    static expiriesUrl = new DocumentsAimsEndPoints(DocumentsAimsEndPoints.controllerUrl
        .add('expiry')
        .toString());

    static categoriesUrl = new DocumentsAimsEndPoints(DocumentsAimsEndPoints.controllerUrl
        .add('category')
        .toString());

}
