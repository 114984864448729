import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AtcAuthService } from '@vueling-ng/atc-auth';

@Component({
  templateUrl: './email-landing.component.html'
})
export class EmailLandingComponent implements OnInit {

    uri: string;

    constructor(private route: ActivatedRoute,
                private auth: AtcAuthService,
                private router: Router) { }

    ngOnInit(): void {
        this.uri = this.route.snapshot.queryParamMap.get('uri');
        const triggerAction = this.route.snapshot.queryParamMap.get('triggerAction');

        if (!this.auth.isAuthenticated()) {
            const queryParams = {};

            if (triggerAction) {
                queryParams['triggerAction'] = triggerAction;
            }

            this.auth.loginRedirect({
                redirectUri: this.uri,
                extraQueryParameters: queryParams,
                scopes: []
            });
        }
        else {
            setTimeout(() => {
                this.router.navigateByUrl(
                    this.router.createUrlTree(
                        [ this.uri ], { queryParams: { triggerAction }}
                    )
                );
            }, 1500);
        }
    }
}
