import { MockResponse } from '@vueling-ng/vy-api-mocks';
import { FullScaleEndPoints } from './full-scale/full-scale.endpoints';

export const scalesMockResponses: MockResponse[] = [
    {
        url: FullScaleEndPoints.searchUrl.toString(),
        response: {
            data: [
            {
                technicalPosition: 1,
                administrativePosition: 1,
                meta4Id: 1,
                id: 1,
                crewInfo: {
                  crewId: 1016,
                  rank: 2,
                  alias: 'JUANP',
                  baseId: 'AGP'
                }
            },
            {
                technicalPosition: 2,
                administrativePosition: 2,
                meta4Id: 2,
                id: 2,
                crewInfo: {
                  crewId: 1014,
                  rank: 2,
                  alias: 'PEDROA',
                  baseId: 'AGP'
                }
            },
            {
                technicalPosition: 3,
                administrativePosition: 3,
                meta4Id: 3,
                id: 3,
                crewInfo: {
                  crewId: 10112,
                  rank: 1,
                  alias: 'ANGELD',
                  baseId: 'BCN'
                }
            }],
            paging: {
                page: 1,
                pageCount: 1,
                totalRecordCount: 10
            }
        }
    },
    {
        url: FullScaleEndPoints.postUrl.toString(),
        response: {
            technicalPosition: 1,
            administrativePosition: 1,
            meta4Id: 1,
            id: 1,
            crewInfo: {
              crewId: 1016,
              rank: 2,
              alias: 'JUANP',
              baseId: 'AGP'
            }
        },
    },
    {
        url: FullScaleEndPoints.postUrl.add('1').add('delete').toString(),
        response: {},
    },
    {
        url: FullScaleEndPoints.postUrl.add('2').add('delete').toString(),
        response: {},
    },
    {
        url: FullScaleEndPoints.postUrl.add('3').add('delete').toString(),
        response: {},
    },

];
