import { RetryInterceptor } from './retry/retry.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { MomentDatesInterceptor } from './moment/moment-dates.interceptor';
import {
    AtcLogHttpErrorInterceptor,
} from '@vueling-ng/atc-log';
import { ServerErrorsInterceptor } from './server-errors/server-errors.interceptor';
import { CacheInterceptor } from './cache/cache.interceptor';
import { AtcAuthInterceptor } from '@vueling-ng/atc-auth';


export const AppInterceptors = [
    {
        provide: HTTP_INTERCEPTORS,
        useClass: ServerErrorsInterceptor,
        multi: true
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: AtcLogHttpErrorInterceptor,
        multi: true
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: CacheInterceptor,
        multi: true
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: MomentDatesInterceptor,
        multi: true
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: RetryInterceptor,
        multi: true
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: AtcAuthInterceptor,
        multi: true
    },
];




