import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';

@Injectable({ providedIn: 'root'})
export class CacheInterceptor implements HttpInterceptor {

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    const headers = request.headers
        .append('Cache-Control', 'no-cache')
        .append('Pragma', 'no-cache')
        .append('Expires', new Date().toISOString());

    const req = request.clone({
        headers
    });

    return next.handle(req);
  }
}
