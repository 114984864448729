import { StringEnum } from '@core/enums/string-enum-type';
import { EndPoints } from '@core/enums/endpoints';

export class CrewEmulationEndPoints extends StringEnum {
  static _controllerUrl = new CrewEmulationEndPoints(EndPoints.webApiBaseUrl
    .concat(EndPoints.webApiVersion1)
    .add('crew-emulation')
    .toString());

  static searchUrl = new CrewEmulationEndPoints(CrewEmulationEndPoints._controllerUrl
    .add('search')
    .toString());

    static byIdUrl = new CrewEmulationEndPoints(CrewEmulationEndPoints._controllerUrl
        .add('by-id')
        .toString());

        static byEmailUrl = new CrewEmulationEndPoints(CrewEmulationEndPoints._controllerUrl
            .add('by-email')
            .toString());

  static postUrl = new CrewEmulationEndPoints(CrewEmulationEndPoints._controllerUrl
    .toString());
}
