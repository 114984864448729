import { Component } from '@angular/core';
import { GoogleTagManagerService } from '@vueling-ng/vy-google-services';
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    constructor(private _googleTagManagerService: GoogleTagManagerService) {}
}
