import { Component } from '@angular/core';

/**
 * Error page that captures the route /error
 */
@Component({
    templateUrl: 'generic.component.html'
})
export class GenericErrorComponent {
}
