import {CanDeactivate, UrlTree} from '@angular/router';
import { NotificationService } from '@core/services/notifications/notification.service';
import { Observable } from 'rxjs';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { Injectable } from '@angular/core';
import { IConfirmOptions } from '@core/services/notifications/confirmOptions';
import { map } from 'rxjs/operators';

export interface IPendingChangesComponent {
  deactivateParams?: IConfirmOptions;
  canDeactivate: () => boolean | Observable<boolean>;
}

/**
 * Angular guard that prevents the user leaving a page if they have pending changes
 * the page must implement IPendingChangesComponent interface in order to work with this guard
 * to set deactivateParams default values you can use inside the page component:
 * 'deactivateParams: PendingChangesMessageParams = new PendingChangesMessageParams();'
 */
export class PendingChangesDefaultParams {

  message: string = _('core.guards.pending-changes.message');
  title: string = _('core.guards.pending-changes.title');
  acceptButtonText?: string = _('core.services.notifications.accept-button');
  declineButtonText?: string = _('core.services.notifications.decline-button');
  width?: string = '350px';
}

@Injectable()
export class PendingChangesGuard implements CanDeactivate<IPendingChangesComponent> {
  constructor(private notifications: NotificationService) {}
  canDeactivate(
    component: IPendingChangesComponent): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (!component || component.canDeactivate()) {
            return true;
        }
        else{
            return this.notifications.presentConfirm(component.deactivateParams || new PendingChangesDefaultParams()).pipe(
                map(() => true)
            );
        }
  }
}
