import { StringEnum } from '@core/enums/string-enum-type';
import { EndPoints } from '@core/enums/endpoints';

export class CrewDocumentDetailEndPoints extends StringEnum {
    public static controllerUrl = new CrewDocumentDetailEndPoints(EndPoints.webApiBaseUrl
        .concat(EndPoints.webApiVersion1)
        .add('documentation')
        .add('crew-document-detail')
        .toString()
    );
    public static getDocumentDetailById = new CrewDocumentDetailEndPoints(CrewDocumentDetailEndPoints.controllerUrl.toString());
}
