import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { NotificationService } from '@core/services/notifications/notification.service';
import { NotificationType } from '@core/services/notifications/notificationType';
/**
 * Angular http interceptor used display error messages on the UI
 */
@Injectable({
  providedIn: 'root'
})
export class ServerErrorsInterceptor implements HttpInterceptor {
    constructor(private notifications: NotificationService) {}
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
        map((event: HttpEvent<any>) =>  event),
        catchError((error: HttpErrorResponse) => {
            if (this.getMessageToDisplay(error)) {
                this.notifications.showToast(
                    this.getMessageToDisplay(error) as string,
                    NotificationType.Error,
                );
            } else {
                this.notifications.onLoadError();
            }
            return throwError(error);
        }));
    }

    getMessageToDisplay(error: HttpErrorResponse): string | void {
        try {
            const errorObject = JSON.parse(error.message);
            if (errorObject?.success === false && errorObject?.message) {
                return errorObject?.message as string;
            }
        } catch {
            return null;
        }
    }
}
