import { MockResponse } from '@vueling-ng/vy-api-mocks';
import * as moment from 'moment';
import { DetachmentsRequestEndPoints } from './request/detachment-request.endpoints';
import { DetachmentEndPoints } from './announcements/detachment.endpoints';
import { DetachmentSlotEndPoints } from './slots/detachment-slot.endpoints';
import { DetachmentsOperativeEndPoints } from './operative/detachment-operative.endpoints';
import { HttpMethods } from '@core/enums/http-methods';

export const detachmentMockResponses: MockResponse[] = [
    {
        url: DetachmentEndPoints.searchUrl.toString(),
        response: {
            data: [
            {
                id: 1,
                rank: 2,
                publishDate: moment.utc().add(-30, 'days'),
                closeDate: moment.utc().add(-5, 'days'),
                countryId: 'LE',
                slotCount: 3
            },
            {
                id: 2,
                rank: 2,
                publishDate: moment.utc().add(-30, 'days'),
                closeDate: moment.utc().add(-5, 'days'),
                countryId: 'LE',
                slotCount: 3
            },
            {
                id: 3,
                rank: 1,
                publishDate: moment.utc().add(-30, 'days'),
                closeDate: moment.utc().add(-15, 'days'),
                countryId: 'LI',
                slotCount: 4
            },
            {
                id: 4,
                rank: 1,
                publishDate: moment.utc().add(-30, 'days'),
                closeDate: moment.utc().add(-5, 'days'),
                countryId: 'LF',
                slotCount: 6
            }],
            paging: {
                page: 1,
                pageCount: 2,
                totalRecordCount: 20
            }
        }
    },
    {
        url: DetachmentsRequestEndPoints.searchUrl.toString(),
        response: {
            data: [
            {
                id: 1,
                detachmentAnnouncementId: 1,
                crewInfo: {
                    rank: 1,
                    crewId: 1014,
                    alias: 'PEDROA',
                    baseId: 'MAD'
                  },
                requestStatus: 1,
                notified: false,
                creationDate: moment.utc().add(-4, 'days'),
                updateDate: moment.utc(),
                minDuration: 3,
                maxDuration: 11,
                baseId: 'BCN',
                rankId: 1,
            },
            {
                id: 2,
                detachmentAnnouncementId: 2,
                crewInfo: {
                    rank: 1,
                    crewId: 1014,
                    alias: 'PEDROA',
                    baseId: 'BCN'
                },
                requestStatus: 1,
                notified: false,
                baseId: 'ORY',
                rankId: 1,
                creationDate: moment.utc().add(-4, 'days'),
                updateDate: moment.utc(),
                minDuration: 3,
                maxDuration: 11,
            },
            {
                id: 3,
                detachmentAnnouncementId: 3,
                crewInfo: {
                    rank: 2,
                    crewId: 1016,
                    alias: 'JUANP',
                    baseId: 'MAD'
                  },
                requestStatus: 0,
                notified: false,
                baseId: 'AGP',
                rankId: 2,
                creationDate: moment.utc().add(-4, 'days'),
                updateDate: moment.utc(),
                minDuration: 3,
                maxDuration: 11,
            },
            {
                id: 4,
                detachmentAnnouncementId: 4,
                crewInfo: {
                    rank: 2,
                    crewId: 1016,
                    alias: 'JUANP',
                    baseId: 'AGP'
                  },
                requestStatus: 2,
                notified: false,
                baseId: 'MAD',
                rankId: 2,
                creationDate: moment.utc().add(-4, 'days'),
                updateDate: moment.utc(),
                minDuration: 3,
                maxDuration: 11,
            },
            {
                id: 5,
                detachmentAnnouncementId: 4,
                crewInfo: {
                    rank: 2,
                    crewId: 1016,
                    alias: 'JUANP',
                    baseId: 'AGP'
                  },
                requestStatus: 2,
                notified: true,
                baseId: 'PMI',
                rankId: 2,
                requestDateTime: moment.utc(),
                minDuration: 3,
                maxDuration: 11,
            }],
            paging: {
                page: 1,
                pageCount: 1,
                totalRecordCount: 10
            }
        }
    },
    {
        url: DetachmentsOperativeEndPoints.searchUrl.toString(),
        response: {
            data: [
            {
                id: 1,
                crewInfo: {
                    rank: 1,
                    crewId: 1014,
                    alias: 'PABLOC',
                    baseId: 'MAD'
                  },
                creationDate: moment.utc().add(-4, 'days'),
                baseId: 'BCN',
                rankId: 1,
            },
            {
                id: 2,
                crewInfo: {
                    rank: 1,
                    crewId: 1014,
                    alias: 'PABLOC',
                    baseId: 'BCN'
                },
                baseId: 'ORY',
                rankId: 1,
                creationDate: moment.utc().add(-4, 'days')
            },
            {
                id: 3,
                crewInfo: {
                    rank: 2,
                    crewId: 1016,
                    alias: 'ANTONIOR',
                    baseId: 'MAD'
                  },
                baseId: 'AGP',
                rankId: 2,
                creationDate: moment.utc().add(-4, 'days'),
            },
            {
                id: 4,
                crewInfo: {
                    rank: 2,
                    crewId: 1016,
                    alias: 'ANTONIOR',
                    baseId: 'AGP'
                  },
                baseId: 'MAD',
                rankId: 2,
                creationDate: moment.utc().add(-4, 'days'),
            }],
            paging: {
                page: 1,
                pageCount: 1,
                totalRecordCount: 10
            }
        }
    },
    {
        url: DetachmentsRequestEndPoints.postUrl.toString(),
        response: {
            id: 1,
            detachmentAnnouncementId: 1,
            crewInfo: {
                rank: 1,
                crewId: 1014,
                alias: 'PEDROA',
                baseId: 'AGP'
              },
            requestStatus: 1,
            creationDate: moment.utc().add(-4, 'days'),
            updateDate: moment.utc(),
            minDuration: 3,
            maxDuration: 11,
            baseId: 'BCN',
        },
    },
    {
        url: DetachmentEndPoints.postUrl.toString(),
        response: {
            id: 1,
            rank: 1,
            publishDate: moment.utc(),
            closeDate: moment.utc().add(1, 'months'),
            countryId: 'LE',
        },
    },
    {
        url: DetachmentEndPoints.postUrl.add('1').add('delete').toString(),
        response: {},
    },
    {
        url: DetachmentEndPoints.postUrl.add('2').add('delete').toString(),
        response: {},
    },
    {
        url: DetachmentEndPoints.postUrl.add('3').add('delete').toString(),
        response: {},
    },
    {
        url: DetachmentEndPoints.postUrl.add('4').add('delete').toString(),
        response: {},
    },
    {
        url: DetachmentSlotEndPoints.forAnnouncementUrl.add('1').toString(),
        response: [
            {
                id: 1,
                detachmentAnnouncementId: 1,
                startDate: moment.utc().add(3, 'months'),
                endDate: moment.utc().add(6, 'months'),
                baseId: 'BCN',
                quantity: 2
            },
            {
                id: 1,
                detachmentAnnouncementId: 1,
                startDate: moment.utc().add(6, 'months'),
                endDate: moment.utc().add(12, 'months'),
                baseId: 'ALC',
                quantity: 1
            },
            {
                id: 1,
                detachmentAnnouncementId: 1,
                startDate: moment.utc().add(3, 'months'),
                endDate: moment.utc().add(6, 'months'),
                baseId: 'ALC',
                quantity: 1
            },
            {
                id: 1,
                detachmentAnnouncementId: 1,
                startDate: moment.utc().add(1, 'months'),
                endDate: moment.utc().add(6, 'months'),
                baseId: 'MAD',
                quantity: 3
            },]
    },
    {
        url: DetachmentSlotEndPoints.forAnnouncementUrl.add('2').toString(),
        response: [
            {
                id: 1,
                detachmentAnnouncementId: 2,
                startDate: moment.utc().add(3, 'months'),
                endDate: moment.utc().add(6, 'months'),
                baseId: 'BCN',
                quantity: 2
            },]
    },
    {
        url: DetachmentSlotEndPoints.forAnnouncementUrl.add('3').toString(),
        response: [
            {
                id: 1,
                detachmentAnnouncementId: 3,
                startDate: moment.utc().add(3, 'months'),
                endDate: moment.utc().add(6, 'months'),
                baseId: 'FCO',
                quantity: 2
            },]
    },
    {
        url: DetachmentSlotEndPoints.forAnnouncementUrl.add('4').toString(),
        response: [
            {
                id: 1,
                detachmentAnnouncementId: 4,
                startDate: moment.utc().add(3, 'months'),
                endDate: moment.utc().add(6, 'months'),
                baseId: 'ORY',
                quantity: 2
            },]
    },
    {
        url: DetachmentSlotEndPoints.postUrl.toString(),
        method: HttpMethods.POST,
        response: {
            id: 1,
            detachmentAnnouncementId: 4,
            startDate: moment.utc().add(3, 'months'),
            endDate: moment.utc().add(6, 'months'),
            baseId: 'ORY',
            quantity: 2
        },
    },
    {
        url: DetachmentsRequestEndPoints.postUrl.add('notify-pending').toString(),
        response: {},
    },
    {
        url: DetachmentEndPoints.postUrl.add('validate').toString(),
        response: true,
    },
];
