import { ConfirmOptions, IConfirmOptions } from './confirmOptions';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar, MatSnackBarRef, TextOnlySnackBar } from '@angular/material/snack-bar';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent, ConfirmDialogResponse } from './components/confirm-dialog/confirm-dialog.component';
import { NotificationType } from '@core/services/notifications/notificationType';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class NotificationService {

  currentToastNotification: Notification;

  constructor(
    private translate: TranslateService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog) { }

  /** Displays a popup message at the top right corner of the page
   * defaultTitle and defaultMessage can be passed to override missing translation errors
   * titleParams and messageParams can be used to show custom text inside the message template
   */
  showToast(
    message: string,
    type: NotificationType = NotificationType.Bare,
    defaultMessage?: string,
    messageParams?: any,
    action?: string,
    timeOut: number = 3500): MatSnackBarRef<TextOnlySnackBar> {
        const toast = this.snackBar.open(this.getTranslated(message, defaultMessage, messageParams), action, {
            duration: timeOut,
            panelClass: 'vy-snackbar-' + type.toString(),
            horizontalPosition: 'end',
            verticalPosition: 'top',
        });

        return toast;
  }

  /** Display a prompt dialog with basic Yes/No response
   *  To see more about dialog check the ConfirmDialogComponent class
   */
   presentConfirm(options?: IConfirmOptions): Observable<any> {
    options = new ConfirmOptions(options);

    const notificationRef = this.dialog.open(ConfirmDialogComponent, {
        width: options.width,
        role: 'alertdialog',
        data: {
          title: options.title,
          message: options.message,
          acceptButtonText: options.acceptButtonText,
          declineButtonText: options.declineButtonText,
        },
        disableClose: true,
    });

    return notificationRef.afterClosed()
        .pipe(
            map((result: ConfirmDialogResponse) => {
                if(result === ConfirmDialogResponse.Declined) {
                    throw(result);
                }

                return result;
            })
        );

    }

  /** Default success message for httpPost calls */
  saveSuccess(): void {
    this.showToast(
      _('core.services.notifications.record-saved'),
      NotificationType.Success
    );
  }

  /** Default error message for httpPost calls */
  saveError(): void {
    this.showToast(
      _('core.services.notifications.save-error'),
      NotificationType.Error
    );
  }

  /** Default error message for httpPost calls */
  public onLoadError(): void {
    this.showToast(
      _('core.services.notifications.load-error'),
      NotificationType.Error,
      "Error loading data"
    );
  }

  private getTranslated(text: string, defaultText?: string, params?: any): string {
    return this.translate.instant(
      text,
      {
        Default: defaultText,
        ...params
      } as object
    ) as string;
  }
}


