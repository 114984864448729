import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { TranslateModule } from "@ngx-translate/core";
import { ModalComponent } from "./modal.component";
import { ModalLayoutService } from "./modal.service";

@NgModule({
  declarations: [
    ModalComponent,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    FlexLayoutModule,
    TranslateModule,
  ],
  providers: [
    ModalLayoutService,
  ],
  exports: [
    ModalComponent,
  ]
})
export class ModalModule { }
