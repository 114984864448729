import { navItems, shortCutItems } from './../../../_nav';
import { AtcAuthService } from '@vueling-ng/atc-auth';
import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import { PageService } from '@app-theme/services/page/page.service';
import { NavData } from '@vueling-ng/vy-theme';

@Component({
    selector: 'app-default-layout',
    templateUrl: './app-default-layout.component.html',
    styleUrls: ['./app-default-layout.component.scss'],
    providers: [ PageService ]
})
export class AppDefaultLayoutComponent {
    //#region .: Variables :.
    public languages: string = environment.languages.join(',');
    public app_display_name: string = environment.app_display_name;
    public old_crew_url: string = environment.old_crew_url;
    public navItems: NavData[] = navItems;
    public shortCutItems: NavData[] = shortCutItems;

    //#endregion

    //#region .: Constructor :.
    constructor(
      public auth: AtcAuthService,
      private pageService: PageService) {
    }
    //#endregion

}
