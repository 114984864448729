export const protectedResourceMap = new Map<string, Array<string>>([ ['https://graph.microsoft.com/v1.0/me', ['User.Read']] ]);


export const defaultEnvironment = {
    production: false,
    use_mock: false,
    app_name: 'VY.Crew.Admin.SPA',
    app_display_name: 'Crew Admin',
    languages: ['es', 'en'], // 'ca',
    atc_auth_config: {
        loginFailedUrl: '/unauthorized',
        framework: {
            unprotectedResources: ['README.md', 'CHANGELOG.md', 'assets'],
            protectedResourceMap: protectedResourceMap
        }
    },
    atc_log_config: {
        console_enabled: true,
        console_level: 'debug'
    }
};
