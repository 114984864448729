import { Injectable } from '@angular/core';
import { HttpService } from '../http-client/http.service';
import { HttpMethods } from '@core/enums/http-methods';
import { ParametersEndPoints } from './parameters-endpoints';
import { Parameter } from '@core/models/parameter';
import { NotificationService } from '@core/services/notifications/notification.service';
import { NotificationType } from '@core/services/notifications/notificationType';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { Observable, of, Subject, throwError } from 'rxjs';
import { map, catchError, share, finalize, filter } from 'rxjs/operators';
import { Dictionary, unset } from 'lodash';

/** Service used to get specific parameters from the server */
@Injectable()
export class ParametersService {
    private parameters: Dictionary<Parameter> = {};
    private parameterObservables: Dictionary<Observable<Parameter>> = {};
    private _onChange: Subject<Parameter> = new Subject();

    constructor(
        private apiService: HttpService,
        private notifications: NotificationService,
    ) { }

    getBaseSnapshotStatus(): Observable<Parameter> {
        return this.getParam('BaseSnapshotStatus', ParametersEndPoints.basesUrl);
    }

    getBaseUpgradeStatus(): Observable<Parameter> {
        return this.getParam('BaseUpgradeStatus', ParametersEndPoints.basesUrl);
    }

    onChange(key: string): Observable<any> {
        return this._onChange.pipe(
            filter(param => param.key === key)
        );
    }

    save(parameter: Parameter, parameterBaseUrl: ParametersEndPoints = ParametersEndPoints.getUrl): Observable<Parameter>{
        return this.apiService
            .call<Parameter>({
                method: HttpMethods.POST,
                url: parameterBaseUrl.toString(),
                body: parameter
            })
            .pipe(
                map((res) => {
                    this.parameters[parameter.key] = res;
                    this._onChange.next(this.parameters[parameter.key]);
                    return this.parameters[parameter.key];
                }),
                catchError((e) => {
                    this._onChange.next(this.parameters[parameter.key]);
                    this.notifications.showToast(
                        _('core.services.parameters.set-parameter-error'),
                        NotificationType.Error
                    );
                    return throwError(e);
                })
            );
    }

    private getParam(paramName: string, parameterBase: ParametersEndPoints = ParametersEndPoints.getUrl): Observable<Parameter> {
        if (this.parameters[paramName]) {
            this._onChange.next(this.parameters[paramName]);
            return of(this.parameters[paramName]);
        }

        if(!this.parameterObservables[paramName]) {
            this.parameterObservables[paramName] =  this.apiService
            .call<Parameter>({
                method: HttpMethods.GET,
                url: parameterBase.add(paramName).toString()
            })
            .pipe(
                map((res) => {
                    this.parameters[paramName] = res;
                    this._onChange.next(this.parameters[paramName]);
                    return this.parameters[paramName];
                }),
                catchError((e) => {
                    this.notifications.showToast(
                        _('core.services.parameters.get-parameter-error'),
                        NotificationType.Error
                    );
                    return throwError(e);
                }),
                share(),
                finalize(() => unset(this.parameterObservables, paramName))
            );
        }


        return this.parameterObservables[paramName];
    }
}
