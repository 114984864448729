import { Component, ContentChild, ElementRef, Input, TemplateRef, ViewChild, OnDestroy } from '@angular/core';
import { ModalLayoutService } from './modal.service';
import { Subscription, of } from 'rxjs';
import { IConfirmOptions } from '@core/services/notifications/confirmOptions';
import { NotificationService } from '@core/services/notifications/notification.service';
import { PendingChangesDefaultParams } from '@core/guards/peding-changes/peding-changes.guard';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnDestroy {

  @Input() public onCloseAction: string;
  @Input() public confirmClose: boolean;
  @Input() public confirmOptions: IConfirmOptions;
  @Input() public title: string;
  @Input() public showClose = true;
  @Input() public centerTitle: boolean;
  @Input() public shouldShowCloseConfirmation = false;

  @ContentChild('subheader', { static: false }) public subheader: TemplateRef<unknown>;
  @ContentChild('body', { static: false }) public body: TemplateRef<unknown>;
  @ContentChild('actions', { static: false }) public actions: TemplateRef<unknown>;

  @ViewChild('dialogClose', { static: false }) public dialogClose: ElementRef<HTMLButtonElement>;

  private _sub: Subscription;

  constructor(public layout: ModalLayoutService, private nofitications: NotificationService) {}

  public close(): void{
    this._sub = (this.confirmClose ? this.nofitications.presentConfirm(this.confirmOptions || new PendingChangesDefaultParams()) : of(true))
        .subscribe(() => this.dialogClose.nativeElement.click());
  }

  public ngOnDestroy(): void {
      this._sub?.unsubscribe();
  }
}
