import { Injectable } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class PageService {

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              private title: Title) {
    this.router.events
    .pipe(
        filter((event) => event instanceof NavigationEnd),
    )
    .subscribe(() => {
      this.setPageTitle();
    });

  }

  private getRoute(): ActivatedRoute {
    let route = this.activatedRoute;
    while (route.firstChild) {
      route = route.firstChild;
    }

    return route.outlet === 'primary'? route : null;
  }

  private setPageTitle() {
    const currentRoute = this.getRoute();
    if(currentRoute) {
        currentRoute.data
        .pipe(
          filter(data => !!data?.headTitle),
        )
        .subscribe(data => {
            this.title.setTitle((data.headTitle as string) + ' | Vueling ' + environment.app_display_name);
        });
    }
  }
}

