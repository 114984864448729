import { StringEnum } from '@core/enums/string-enum-type';
import { EndPoints } from '@core/enums/endpoints';

export class BaseRequestUpgradeEndPoints extends StringEnum {
  private static _controllerUrl = new BaseRequestUpgradeEndPoints(EndPoints.webApiBaseUrl
    .concat(EndPoints.webApiVersion1)
    .add('base-request')
    .add('upgrade')
    .toString());

  static searchUrl = new BaseRequestUpgradeEndPoints(BaseRequestUpgradeEndPoints._controllerUrl
    .add('search')
    .toString());

  static notifyUrl = new BaseRequestUpgradeEndPoints(BaseRequestUpgradeEndPoints._controllerUrl
        .add('notify')
        .toString());

  static postUrl = new BaseRequestUpgradeEndPoints(BaseRequestUpgradeEndPoints._controllerUrl
    .toString());
}
