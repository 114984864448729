import { EndPoints } from '@core/enums/endpoints';
import { StringEnum } from '@core/enums/string-enum-type';

export class XoffAnnouncementDetailsEndpoints extends StringEnum {
    public static controllerUrl = new XoffAnnouncementDetailsEndpoints(
        EndPoints.webApiBaseUrl.concat(EndPoints.webApiVersion1).add('xoff-announcement-details').toString(),
    );

    public static postUrl = new XoffAnnouncementDetailsEndpoints(XoffAnnouncementDetailsEndpoints.controllerUrl.toString());

    public static forAnnouncement = new XoffAnnouncementDetailsEndpoints(XoffAnnouncementDetailsEndpoints.controllerUrl.add('for-announcement').toString());
}
