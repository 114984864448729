import { NgModule } from '@angular/core';
import { Routes, Route, RouterModule } from '@angular/router';
import { RoleNotAllowedComponent } from './pages/error/role-not-allowed/role-not-allowed.component';
import { GenericErrorComponent } from './pages/error/generic/generic.component';
import { Error404Component } from './pages/error/not-found/404.component';
import { EmailLandingComponent } from './pages/email-landing/email-landing.component';

const routes: Route[] | Routes = [
    {
        path: 'email-action',
        component: EmailLandingComponent,
        data: {
          title: 'Email Action',
        }
    },
    {
        path: 'error',
        children: [
            {
                path: 'unauthorized',
                component: RoleNotAllowedComponent,
                data: {
                    title: 'Unauthorized',
                }
            },
            {
                path: 'not-found',
                component: Error404Component,
                data: {
                    title: 'Page not found',
                }
            },
            {
                path: '**',
                data: {
                    title: 'Error',
                },
                component: GenericErrorComponent,
            },
        ],
    },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CoreRoutingModule { }
