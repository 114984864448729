import { MockResponse } from '@vueling-ng/vy-api-mocks';
import * as moment from 'moment';
import { FeatureToggleEndPoints } from './feature-toggle/feature-toggle.endpoints';

export const featureToggleMockResponses: MockResponse[] = [
    {
        url: FeatureToggleEndPoints.searchUrl.toString(),
        response: {
            data: [
            {
                id: 1,
                updateDate: moment.utc(),
                value: true,
                code: 'partTimeNavItem',
                description: 'Part Time menu item in Mycrew'
            },
            {
                id: 1,
                updateDate: moment.utc(),
                value: true,
                code: 'basesNavItem',
                testMode: true,
                description: 'Bases menu item in Mycrew'
            }
            ],
            paging: {
                page: 1,
                pageCount: 1,
                totalRecordCount: 2
            }
        }
    },
    {
        url: FeatureToggleEndPoints.postUrl.toString(),
        response: {
            id: 1,
            updateDate: moment.utc(),
            value: true,
            code: 'partTimeNavItem',
            description: 'Part Time menu item in Mycrew'
        },
    },
    {
        url: FeatureToggleEndPoints.postUrl.add('1').add('delete').toString(),
        response: true,
    },

];
