import { StringEnum } from '@core/enums/string-enum-type';
import { EndPoints } from '@core/enums/endpoints';

export class BaseRequestSnapshotEndPoints extends StringEnum {
  private static _controllerUrl = new BaseRequestSnapshotEndPoints(EndPoints.webApiBaseUrl
    .concat(EndPoints.webApiVersion1)
    .add('base-request')
    .add('snapshot')
    .toString());

  static searchUrl = new BaseRequestSnapshotEndPoints(BaseRequestSnapshotEndPoints._controllerUrl
    .add('search')
    .toString());

    static datesUrl = new BaseRequestSnapshotEndPoints(BaseRequestSnapshotEndPoints._controllerUrl
        .add('dates')
        .toString());

  static notifyUrl = new BaseRequestSnapshotEndPoints(BaseRequestSnapshotEndPoints._controllerUrl
        .add('notify')
        .toString());
        
  static regenerateUrl = new BaseRequestSnapshotEndPoints(BaseRequestSnapshotEndPoints._controllerUrl
        .add('regenerate')
        .toString());  

  static postUrl = new BaseRequestSnapshotEndPoints(BaseRequestSnapshotEndPoints._controllerUrl
    .toString());
}
