import { HttpMethods } from '@core/enums/http-methods';
import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent,
} from '@angular/common/http';

import { Observable, of, throwError } from 'rxjs';
import { retryWhen, mergeMap, delay } from 'rxjs/operators';
/**
 * Angular http interceptor used display error messages on the UI
 */
@Injectable({
  providedIn: 'root'
})
export class RetryInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            retryWhen(errors =>
              errors.pipe(
                mergeMap((error, count) => {
                  if (count <= 5 && (error.status === 401 || error.method === HttpMethods.GET)) {
                    return  of(error);
                  }

                  return throwError(error);
                }),
                delay(1000)
              )
            ),

        );
    }
}
