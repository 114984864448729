import { ParametersEndPoints } from './parameters/parameters-endpoints';
import { MockResponse } from '@vueling-ng/vy-api-mocks';
import { HttpMethods } from '@core/enums/http-methods';

export const coreMockResponses: MockResponse[] = [
    {
        url: ParametersEndPoints.basesUrl.add('BaseSnapshotStatus').toString(),
        response: {
            key: 'BaseSnapshotStatus',
            value: 'false',
        },
    },
    {
        url: ParametersEndPoints.basesUrl.add('BaseUpgradeStatus').toString(),
        response: {
            key: 'BaseUpgradeStatus',
            value: 'true',
        },
    },
    {
        url: ParametersEndPoints.basesUrl.toString(),
        method: HttpMethods.POST,
        response: {
            key: 'BaseUpgradeStatus',
            value: 'true',
        },
    },
];
