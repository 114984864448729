import { StringEnum } from '@core/enums/string-enum-type';
import { EndPoints } from '@core/enums/endpoints';

export class DocumentTypeFilesEndPoints extends StringEnum {
    static controllerUrl = new DocumentTypeFilesEndPoints(EndPoints.webApiBaseUrl
        .concat(EndPoints.webApiVersion1)
        .add('documentation')
        .add('document-type')
        .add('file')
        .toString()
    );

    static forDocumentUrl = new DocumentTypeFilesEndPoints(DocumentTypeFilesEndPoints.controllerUrl
        .add('for-type')
        .toString()
      );
}
