import { MockResponse } from '@vueling-ng/vy-api-mocks';
import { DaysEndPoints } from "./days/days.endpoint";
import { PartTimeRequestsEndPoints } from "./requests/part-time-requests.endpoint";

export const partTimeMockResponses: MockResponse[] = [
    {
        url: DaysEndPoints.partTimeDays.toString(),
        response: {
            data: [
            {
                "crewId": 5316,
                "chequeo": "NOM5316",
                "rankId": "CP",
                "base": "BCN",
                "patternId": 4,
                "reductionPercentage": 24,
                "TheoricalDays": 7,
                "beginDate": null,
                "endDate": null,
                "offBalanced": null,
                "updateDate": null
            },
            {
                "crewId": 1008,
                "chequeo": "AMS1008",
                "rankId": "CP",
                "base": "BCN",
                "patternId": 4,
                "reductionPercentage": 24,
                "TheoricalDays": 7,
                "beginDate": null,
                "endDate": null,
                "offBalanced": null,
                "updateDate": null
            },
            {
                "crewId": 1024,
                "chequeo": "BER1024",
                "rankId": "CP",
                "base": "BCN",
                "patternId": -1,
                "reductionPercentage": 30,
                "TheoricalDays": 9,
                "beginDate": "2023-06-07T00:00:00Z",
                "endDate": "2023-06-16T00:00:00Z",
                "offBalanced": false,
                "updateDate": "2023-04-12T10:33:53.483Z"
            },
            {
                "crewId": 6069,
                "chequeo": "CRI6069",
                "rankId": "CP",
                "base": "BCN",
                "patternId": 4,
                "reductionPercentage": 24,
                "TheoricalDays": 7,
                "beginDate": "2023-06-09T00:00:00Z",
                "endDate": "2023-06-15T00:00:00Z",
                "offBalanced": true,
                "updateDate": "2023-04-12T10:33:53.483Z"
            }
        ]
        }
    },
    {
        url: PartTimeRequestsEndPoints.partTimeRequests.toString(),
        response: {
            data: [
                {
                    "crewId": 5316,
                    "crewInfo": {
                        "crewId": 5316,
                        "alias": "NOM5316",
                    },
                    "beginDate": "2023-06-07T00:00:00Z",
                    "endDate": "2023-06-16T00:00:00Z",
                    "requestStatus": 1,
                    "updateDate": "2023-04-12T10:33:53.483Z",
                    "categoryId": 1,
                    "reductionPercentage": 24
                },
                {
                    "crewId": 1008,
                    "crewInfo": {
                        "crewId": 1008,
                        "alias": "AMS1008",
                    },
                    "beginDate": "2023-06-07T00:00:00Z",
                    "endDate": "2023-06-16T00:00:00Z",
                    "requestStatus": 2,
                    "updateDate": "2023-04-12T10:33:53.483Z",
                    "categoryId": 1,
                    "reductionPercentage": 13
                },
            ]
        }
    }
];

