import { StringEnum } from '@core/enums/string-enum-type';
import { EndPoints } from '@core/enums/endpoints';

export class BaseEndPoints extends StringEnum {
  static controllerUrl = new BaseEndPoints(EndPoints.webApiBaseUrl
    .concat(EndPoints.webApiVersion1)
    .add('bases')
    .toString());

    static countryUrl = new BaseEndPoints(BaseEndPoints.controllerUrl
        .add('by-country')
        .toString());
    static searchUrl = new BaseEndPoints(BaseEndPoints.controllerUrl
      .add('search')
      .toString());

    static saveBaseUrl = new BaseEndPoints(BaseEndPoints.controllerUrl
      .add('base-availability')
      .toString());

    static saveDetachmentUrl = new BaseEndPoints(BaseEndPoints.controllerUrl
      .add('detachment-availability')
      .toString());
}
