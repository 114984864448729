import { StringEnum } from '@core/enums/string-enum-type';
import { EndPoints } from '@core/enums/endpoints';

export class DetachmentsRequestEndPoints extends StringEnum {
  private static _controllerUrl = new DetachmentsRequestEndPoints(EndPoints.webApiBaseUrl
    .concat(EndPoints.webApiVersion1)
    .add('detachment-request')
    .toString());

  static searchUrl = new DetachmentsRequestEndPoints(DetachmentsRequestEndPoints._controllerUrl
    .add('search')
    .toString());

  static postUrl = new DetachmentsRequestEndPoints(DetachmentsRequestEndPoints._controllerUrl
    .toString());
}
