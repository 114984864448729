import { Component } from '@angular/core';

/**
 * Error page that captures errors based in user authorization
 */
@Component({
    templateUrl: 'role-not-allowed.component.html'
})
export class RoleNotAllowedComponent {
}
