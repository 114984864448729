import { coreMockResponses } from "@core/services/responses.mock";
import { baseChangesMockResponses } from "@features/bases/services/responses.mock";
import { detachmentMockResponses } from "@features/detachments/services/responses.mock";
import { documentationMockResponses } from "@features/documentation/services/responses.mock";
import { emulationMockResponses } from "@features/emulation/services/responses.mock";
import { featureToggleMockResponses } from "@features/feature-toggle/services/responses.mock";
import { partTimeMockResponses } from "@features/part-time/services/response.mock";
import { scalesMockResponses } from "@features/scales/services/responses.mock";
import { xoffResponses } from "@features/xoff/components/services/responses.mock";
import { sharedMockResponses } from "@shared/services/responses.mock";
import { MockResponse } from "@vueling-ng/vy-api-mocks";
import { concat } from "lodash";

export const APP_MOCK_CONFIG: MockResponse[] = concat(
    coreMockResponses,
    baseChangesMockResponses,
    detachmentMockResponses,
    featureToggleMockResponses,
    sharedMockResponses,
    scalesMockResponses,
    emulationMockResponses,
    documentationMockResponses,
    partTimeMockResponses,
    xoffResponses
);
