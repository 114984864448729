import { StringEnum } from '@core/enums/string-enum-type';
import { EndPoints } from '@core/enums/endpoints';

export class FeatureToggleEndPoints extends StringEnum {
  static _controllerUrl = new FeatureToggleEndPoints(EndPoints.webApiBaseUrl
    .concat(EndPoints.webApiVersion1)
    .add('feature-toggle')
    .toString());

  static searchUrl = new FeatureToggleEndPoints(FeatureToggleEndPoints._controllerUrl
    .add('search')
    .toString());

  static postUrl = new FeatureToggleEndPoints(FeatureToggleEndPoints._controllerUrl
    .toString());
}
